
import {
  IonCard,
  IonCardContent,
  actionSheetController,
  toastController,
  IonList,
  IonItem,
  IonFab,
  IonFabButton,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonModal,
} from '@ionic/vue';
import {defineComponent, ref} from 'vue';
import {mapActions} from 'vuex';
import {useI18n} from 'vue-i18n';
import {
  ellipsisVerticalOutline,
  closeOutline,
  cloudDownloadOutline,
  starOutline,
  star,
  ellipseOutline,
  checkmarkCircleOutline,
  chevronForwardOutline,
} from 'ionicons/icons';
import Container from "../layout/Container.vue";
import ApiService from "@/services/api.service";
import ContentBlock from "@/components/information/ContentBlock.vue";
import mixin from "@/mixin";
import {useRouter} from "vue-router";
import DownloadService from "@/services/download.service";
import Modal from "@/components/Modal.vue";

export default defineComponent({
  name: 'item',
  components: {
    IonCard,
    IonCardContent,
    ContentBlock,
    Container,
    IonList,
    IonItem,
    IonFab,
    IonFabButton,
    IonIcon,
    IonCardHeader,
    IonCardTitle,
    IonModal,
    Modal,
  },
  setup() {
    const {t} = useI18n();
    const router = useRouter();
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;

    return {
      t,
      router,
      isOpenRef,
      setOpen,
      ellipsisVerticalOutline,
      cloudDownloadOutline,
      ellipseOutline,
      checkmarkCircleOutline,
      chevronForwardOutline,
    }
  },
  data() {
    return {
      record: {
        id: null,
        favorite: false,
        children: [] as any[],

      },
      attachment: []
    };
  },

  mixins: [
    mixin
  ],

  computed: {
    favoritesButtonIcon(): string {
      return this.record.favorite ? star : starOutline;
    },
    isChild(): boolean {
      return this.$route.params.child !== undefined;
    }
  },

  methods: {
    ionViewWillEnter() {
      this.getItem();
    },

    ...mapActions({
      fetchItems: 'information/fetchItems'
    }),

    async getItem() {
      if (!this.$route.params.id) {
        await this.$router.push('/information');
        return;
      }

      let url = `/tasks/content-item/${this.$route.params.id}`;
      // let readUrl = `/tasks/content-item/read/${this.$route.params.id}`;

      if (this.$route.params.child) {
        url = `/tasks/content-item/${this.$route.params.id}/${this.$route.params.child}`;
        // readUrl = `/tasks/content-item/read/${this.$route.params.id}/${this.$route.params.child}`;
      }

      const {data} = await ApiService.get(url);
      this.record = data;

      await ApiService.put(url, {});
      // ApiService.post(readUrl, {});
      this.fetchItems(true);
    },

    async download(file: any) {
      if (file.type == 'text') {
        this.attachment = file;
        this.setOpen(true);
      } else {
        DownloadService.downloadFile(file.type !== 'image' ? file.downloadLink : file.original.downloadLink, file.name);
      }
    },
    async showOptions() {
      let unreadUrl = `/tasks/content-item/unread/${this.$route.params.id}`;

      if (this.$route.params.child) {
        unreadUrl = `/tasks/content-item/unread/${this.$route.params.id}/${this.$route.params.child}`;
      }

      const sheet = await actionSheetController.create({
        'header': this.t('exercises.actions'),
        buttons: [
          {
            text: this.record.favorite ? this.t('information.untoggleFavorite') : this.t('information.toggleFavorite'),
            handler: () => this.toggleFavorite()
          },
          {
            text: this.t('information.markAsUnread'),
            handler: async () => {
              await ApiService.post(unreadUrl, {});

              const toast = await toastController.create({
                message: this.t('information.unreadMessage'),
                duration: 3000,
              });

              await toast.present();
              await this.$router.back();
            }
          },
          {
            text: this.t('cancel'),
            icon: closeOutline,
            role: 'cancel'
          }
        ],
      });

      await sheet.present();
    },

    async toggleFavorite() {
      this.record.favorite = ! this.record.favorite;

      await ApiService.post(`/tasks/content-item/favorite/${this.record.id}`, {});

      const message = this.record.favorite ? 'information.markAsFavorite' : 'information.markAsUnFavorite';
      const toast = await toastController.create({
        message: this.t(message),
        duration: 3000,
      })

      await toast.present();
    }
  },
});
