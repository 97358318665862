<template>
  <div>
    <IonCard :class="{'line': block.visual === 'line'}">
      <IonCardHeader @click="toggle = !toggle" v-if="block.visual === 'accordeon'">
        <IonCardTitle>{{ block.title }}</IonCardTitle>
        <IonIcon :icon="hideContent ? chevronDownOutline : chevronUpOutline" />
      </IonCardHeader>
      <IonCardContent :class="{'ion-hide': hideContent}">
        <div v-if="block.vimeo_identifier">
          <p v-if="block.vimeo_status === 'transcoding'" class="ion-padding">
              {{ t('information.videoProcessing') }}
          </p>
          <video v-else :poster="block.vimeo_poster ?? 'assets/default-video-image.png'" controls style="width: 100%">
            <source :src="block.vimeo_url">
          </video>
        </div>
        <div class="item-card block-value" v-html="block.body.value"></div>

        <p v-if="answer">
          {{ t('information.yourAnswer') }} {{ answer }}
        </p>
        <IonButton v-if="block.answer_option" color="success" shape="round" expand="full" @click="setOpen(true)" class="ion-margin-top">
          {{ t('information.answerBtn') }}
        </IonButton>

        <Test
            v-for="test in block.tests"
            :key="test.id"
            :id="test.id"
            :inline="true" />
      </IonCardContent>
    </IonCard>

    <IonModal
      v-if="block.answer_option"
      :is-open="modalRef"
      @didDismiss="setOpen(false)"
    >
      <Modal @dismiss="setOpen(false)" :title="t('information.answer')">
        <form @submit.prevent="updateAnswer">
          <InputGroup v-model="answer" type="textarea" color="primary" :placeholder="t('information.answerPlaceholder')" />
          <Button expand="full" type="submit" color="success">{{ t('send') }}</Button>
        </form>
      </Modal>
    </IonModal>
  </div>
</template>

<script type="ts">
import {defineComponent, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  IonModal,
  toastController,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from '@ionic/vue';
import {
  chevronDownOutline,
  chevronUpOutline,
} from 'ionicons/icons';
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal";
import InputGroup from "@/components/InputGroup";
import ApiService from "@/services/api.service";
import Test from "@/components/tests/Test.vue";

export default defineComponent({
  name: 'ContentBlock',
  components: {
    Test,
    Button,
    Modal,
    InputGroup,
    IonModal,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
  },
  props: {
    block: {
      type: Object,
      required: true,
    }
  },
  setup() {
    const { t } = useI18n();
    const modalRef = ref(false);
    const setOpen = (open) => modalRef.value = open;

    return {
      t,
      modalRef,
      setOpen,
      chevronDownOutline,
      chevronUpOutline,
    };
  },
  data() {
    return {
      answer: this.block.answer ?? '',
      toggle: true,
    };
  },
  computed: {
    hideContent() {
      return this.block.visual === 'accordeon' && this.toggle;
    }
  },
  methods: {
    async updateAnswer() {
      await ApiService.post(`/tasks/content-item/updateAnswer/${this.block.id}`, {
        answer: this.answer,
      });

      const toast = await toastController.create({
        message: this.t('information.answerSubmitted'),
        duration: 3000,
      });

      await toast.present();

      this.setOpen(false);
    }
  }
});
</script>

<style lang="scss">
.block-value {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}

ion-card.line {
  border-left: 6px solid #000;

  ion-card-content {
    padding-inline-start: 14px;
  }
}

ion-card-header {
  display: flex;
  justify-content: space-between;

  ion-card-title {
    font-size: 1.4rem;
  }

  ion-icon {
    font-size: 1.4rem;
  }
}
</style>
