<template>
  <IonHeader>
    <IonToolbar>
      <IonTitle>{{ title }}</IonTitle>
      <IonIcon class="closeIcon" @click="$emit('dismiss')" slot="end" :icon="closeOutline" />
    </IonToolbar>
  </IonHeader>
  <IonContent id="modalContent" class="ion-padding">
    <slot />
  </IonContent>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonIcon } from '@ionic/vue';
import {defineComponent } from 'vue';
import {useI18n} from "vue-i18n";
import { closeOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Modal',
  emits: ["dismiss"],
  props: {
    title: { type: String, default: 'Action' },
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
      closeOutline,
    }
  },

  methods: {
    triggerScrollTop() {
      const content = document.querySelector('#modalContent');
      if (content) {
        content.scrollToTop(0);
      }
    },
  },

  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonIcon }
});
</script>

<style lang="scss">
.closeIcon {
  font-size: 1.75rem;
  padding: 10px;
}
</style>
